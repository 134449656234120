/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'details_open': {
    width: 28,
    height: 28,
    viewBox: '0 0 28 28',
    data: '<defs><filter x="-12.5%" y="-12.5%" width="133.3%" height="133.3%" filterUnits="objectBoundingBox" id="svgicon_details_open_a"><feMorphology radius=".5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/><feOffset dx="1" dy="1" in="shadowSpreadOuter1" result="shadowOffsetOuter1"/><feGaussianBlur stdDeviation="1" in="shadowOffsetOuter1" result="shadowBlurOuter1"/><feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/><feColorMatrix values="0 0 0 0 0.505882353 0 0 0 0 0.505882353 0 0 0 0 0.505882353 0 0 0 0.2 0" in="shadowBlurOuter1"/></filter><circle pid="0" id="svgicon_details_open_b" cx="12" cy="12" r="12"/></defs><g _fill="none" fill-rule="evenodd"><g transform="translate(1 1)"><use _fill="#000" filter="url(#svgicon_details_open_a)" xlink:href="#svgicon_details_open_b"/><use _stroke="#F9F9F9" _fill="#FFF" xlink:href="#svgicon_details_open_b"/></g><path pid="1" d="M9.464 11.293a1 1 0 00-1.414 1.414l4.066 4.066a1.25 1.25 0 001.768 0l4.066-4.066a1 1 0 10-1.414-1.414L13 14.828l-3.536-3.535z" _fill="#154F4A" fill-rule="nonzero"/></g>'
  }
})
